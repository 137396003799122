<template>
  <dv-border-box-11
    title="合作门店数量"
    backgroundColor="rgba(19, 25, 47, 0.6)"
    class="pie-chart-box"
  >
    <div id="barChart" style="width: 100%; height: 100%"></div>
  </dv-border-box-11>
</template>

<script>
export default {
  data() {
    return {
      option: null,
      pie: null,
      dataList: this.$store.state.sairoboData.industryPropertyList,
    };
  },

  computed: {
    valueList() {
      let valueList = [];
      this.dataList.forEach((item) => {
        valueList.push(item.orgCount);
      });
      return valueList;
    },

    nameList() {
      let nameList = [];
      this.dataList.forEach((item) => {
        nameList.push(item.industryName);
      });
      return nameList;
    },
  },

  mounted() {
    var chartDom = document.getElementById("barChart");
    this.barChart = this.$echarts.init(chartDom);
    this.initBarChart();
    setInterval(() => {
      this.initBarChart();
    }, 5000)
  },

  methods: {
    initBarChart() {
      this.option = {
        color: [
          "rgba(43,189,189,1)",
          "rgba(253,170,110,1)",
          "rgba(123,133,164,1)",
          "rgba(222,199,15,1)",
        ],
        xAxis: {
          type: "category",
          data: this.nameList,
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
        },
        series: [
          {
            data: this.valueList,
            type: "bar",
            barWidth: 40,
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList = [
                    "rgba(43,189,189,1)",
                    "rgba(253,170,110,1)",
                    "rgba(123,133,164,1)",
                    "rgba(222,199,15,1)",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      this.option && this.barChart.setOption(this.option);
    },
  },
};
</script>

<style lang="scss" scoped>
.pie-chart-box {
  width: 100%;
  // height: 3.7rem;
  flex: 1;
  padding: 0.3rem 0.2rem 0;
}
</style>