<template>
  <dv-border-box-11
    title="注册学员/活跃学员"
    backgroundColor="rgba(19, 25, 47, 0.6)"
    class="stacked-area-chart-box"
  >
    <div id="main" style="width: 100%; height: 100%"></div>
  </dv-border-box-11>
</template>

<script>
export default {
  data() {
    return {
      option: null,
      areaChart: null,
      dataList: this.$store.state.sairoboData.stuNumTrendList,
    };
  },

  computed: {
    regNum() {
      let number = [];
      this.dataList.forEach((item) => {
        number.push(item.regNum);
      });
      return number;
    },

    onlineNum() {
      let number = [];
      this.dataList.forEach((item) => {
        number.push(item.onlineNum);
      });
      return number;
    },

    month() {
      let month = [];
      this.dataList.forEach((item) => {
        month.push(item.month);
      });
      return month;
    },
  },

  mounted() {
    var chartDom = document.getElementById("main");
    this.areaChart = this.$echarts.init(chartDom);
    this.initAreaChart();
    setInterval(() => {
      this.initAreaChart();
    }, 5000)
  },

  methods: {
    initAreaChart() {
      this.option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "auto",
            },
          },
        },

        legend: {
          data: ["注册学员", "活跃学员"],
          textStyle: {
            color: "#fff",
          },
        },

        toolbox: {
          // feature: {
          //   saveAsImage: {},
          // },
        },

        grid: {
          left: "3%",
          right: "4%",
          top: "15%",
          containLabel: true,
        },

        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.month,
            axisLabel: {
              color: "#fff",
            },
          },
        ],

        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#fff",
            },
          },
        ],

        series: [
          {
            name: "注册学员",
            type: "line",
            stack: "总量",
            areaStyle: {
              color: "rgba(43,189,189,1)",
            },
            itemStyle: {
              normal: {
                color: "rgba(43,189,189,1)",
              },
              lineStyle: {
                color: "rgba(43,189,189,1)",
              },
            },
            symbol: "circle",
            emphasis: {
              focus: "series",
            },
            data: this.regNum,
          },
          {
            name: "活跃学员",
            type: "line",
            stack: "总量",
            areaStyle: {
              color: "rgba(46,144,209,1)",
            },
            itemStyle: {
              normal: {
                color: "rgba(46,144,209,1)",
              },
              lineStyle: {
                color: "rgba(46,144,209,1)",
              },
            },
            symbol: "circle",
            emphasis: {
              focus: "series",
            },
            data: this.onlineNum,
          },
        ],
      };
      this.option && this.areaChart.setOption(this.option);
    },
  },
};
</script>

<style lang="scss" scoped>
.stacked-area-chart-box {
  width: 100%;
  // height: 3.7rem;
  flex: 1;
  padding: 1rem 0.2rem 0;
}
</style>