<template>
  <div id="top-header">
    <div class="header-left">
      <dv-decoration-8 class="header-right-decoration" />
      <div class="dataweather">
        {{ dataWeather.city }}
        {{ dataWeather.weathertemp }}
        <img :src="dataWeather.icontemp" alt="" />
        {{ dataWeather.peraturetemp }}
      </div>
      <dv-decoration-10 class="header-left-decoration" />
    </div>
    <div class="header-center">
      <dv-decoration-6
        class="header-center-decoration"
        :color="['#50e3c2', '#67a1e5']"
        style="height: 0.1rem; width: 100%"
      />
      <div class="center-title">SAIROBO平台数据分析看板</div>
    </div>
    <div class="header-right">
      <dv-decoration-8 class="header-right-decoration" :reverse="true" />
      <div class="datatime">
        <span>
          {{ dataTime.dateYear }} {{ dataTime.dateWeek }} {{ dataTime.dateDay }}
        </span>
      </div>
      <dv-decoration-10
        class="header-left-decoration"
        style="transform: rotateY(180deg)"
      />
    </div>
  </div>
</template>

<script>
import { formatTime } from "../utils/index.js";
import { weatherOption } from "../config/weatherOption";
export default {
  name: "topHeader",
  data() {
    return {
      dataTime: {
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      },
      dataWeather: {
        city: "北京市",
        dayweather: "晴",
        nightweather: "多云",
        weathertemp: "晴", // 根据时间判断选白天还是晚上的天气
        nighttemp: "3℃",
        daytemp: "15℃",
        peraturetemp: "3℃~15℃", // 根据时间判断选白天还是晚上的温度
        icontemp: "https://cdn.heweather.com/cond_icon/100.png",
      },
    };
  },
  created() {
    this.getDateTime();
    this.requestGetWeather();
  },
  methods: {
    // 获取时间
    getDateTime() {
      setInterval(() => {
        this.dataTime.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dataTime.dateWeek = this.dataTime.weekday[new Date().getDay()];
        this.dataTime.dateDay = formatTime(new Date(), "HH: mm: ss");
      }, 1000);
    },
    // 获取天气
    requestGetWeather() {
      $.get({
        //这里的url不能写到data对象的外面
        url: "https://api.asilu.com/weather_v2/",
        dataType: "jsonp", // 请求方式为jsonp
        success: (data) => {
          if (data.status === "1") {
            let currTime = new Date().getHours();
            let dailyForecast = data.forecasts[0].casts[0];
            let city = data.forecasts[0].city;
            let daytemp = dailyForecast.daytemp + "℃";
            let nighttemp = dailyForecast.nighttemp + "℃";
            let weathertemp = "";
            let peraturetemp = "";
            if (currTime >= 6 && currTime < 18) {
              weathertemp = dailyForecast.dayweather;
              peraturetemp =
                dailyForecast.nighttemp + "℃~" + dailyForecast.daytemp + "℃";
            } else {
              weathertemp = dailyForecast.nightweather;
              peraturetemp =
                dailyForecast.daytemp + "℃~" + dailyForecast.nighttemp + "℃";
            }
            let icontemp =
              "https://cdn.heweather.com/cond_icon/" +
              weatherOption.weatherCode[weathertemp] +
              ".png";
            this.dataWeather = {
              city,
              daytemp,
              nighttemp,
              weathertemp,
              peraturetemp,
              icontemp,
            };
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#top-header {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  .header-center {
    flex: 1;
    .header-center-decoration {
      margin-top: 0.8rem;
    }
    .center-title {
      position: absolute;
      font-size: 0.4rem;
      font-weight: bold;
      left: 50%;
      top: 0.4rem;
      transform: translateX(-50%);
    }
  }
  .header-left {
    width: 25%;
    height: 1rem;
    position: relative;
    .header-left-decoration {
      position: absolute;
      right: 0;
      top: 0;
    }
    .dataweather {
      z-index: 999;
      position: absolute;
      top: 0;
      left: 1.1rem;
      font-size: 0.22rem;
      color: #fff;
      height: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 0.3rem;
        height: 0.3rem;
        filter: brightness(3);
        vertical-align: bottom;
      }
    }
  }

  .header-right {
    width: 25%;
    height: 1rem;
    position: relative;
    .header-left-decoration {
      position: absolute;
      left: 0;
      top: 0;
    }
    .datatime {
      z-index: 999;
      position: absolute;
      top: 0;
      right: 1.1rem;
      font-size: 0.22rem;
      color: #fff;
      height: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .header-left-decoration,
  .header-right-decoration {
    height: 1rem;
  }
}
</style>
