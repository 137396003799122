<template>
  <dv-border-box-11
    title="行业属性"
    backgroundColor="rgba(19, 25, 47, 0.6)"
    class="pie-chart-box"
  >
    <div id="pieChart2" style="width: 100%; height: 100%"></div>
  </dv-border-box-11>
</template>

<script>
export default {
  data() {
    return {
      option: null,
      pie: null,
      dataList: this.$store.state.sairoboData.industryPropertyList,
    };
  },

  mounted() {
    console.log('行业属性和门店数量', this.$store.state.sairoboData)
    var chartDom = document.getElementById("pieChart2");
    this.pieChart2 = this.$echarts.init(chartDom);
    this.initpieChart2();
    setInterval(() => {
      this.initpieChart2();
    }, 5000)
  },

  computed: {
    valueList () {
      let valueList = []
      this.dataList.forEach(item => {
        valueList.push({ value: item.orgCount, name: item.industryName })
      })
      return valueList
    },

    nameList () {
      let nameList = []
      this.dataList.forEach(item => {
        nameList.push(item.industryName)
      })
      return nameList
    },
  },

  methods: {
    initpieChart2() {
      this.option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        color:['rgba(43,189,189,1)', 'rgba(253,170,110,1)', 'rgba(123,133,164,1)','rgba(222,199,15,1)'],
        legend: {
          top: "10%",
          left: "center",
          data: this.nameList,
          textStyle: {
            color: '#fff',
            fontSize: 12
          },
        },
        label: {
          formatter: '{d}%',
        },
        toolbox: {
          show: true,
          // feature: {
          //   mark: { show: true },
          //   dataView: { show: true, readOnly: false },
          //   restore: { show: true },
          //   saveAsImage: { show: true },
          // },
        },
        series: [
          {
            name: "面积模式",
            type: "pie",
            radius: [30, 90],
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 5,
            },
            data: this.valueList,
          },
        ],
      };
      this.option && this.pieChart2.setOption(this.option);
    },
  },
};
</script>

<style lang="scss" scoped>
.pie-chart-box {
  width: 100%;
  // height: 3.7rem;
  flex: 1;
  padding: 0.7rem 0.2rem 0;
}
</style>