<template>
  <dv-border-box-8 class="total-number" backgroundColor="rgba(19, 25, 47, 0.6)">
    <div class="total-number-inner">
      <div class="number-item">
        <span>系统学员总数</span>
        <ul>
          <li v-for="(item, index) in totalNumber" :key="index">{{ item }}</li>
        </ul>
      </div>
      <div class="number-item">
        <span>活跃学员数</span>
        <ul>
          <li v-for="(item, index) in activeNumber" :key="index">{{ item }}</li>
        </ul>
      </div>
    </div>
  </dv-border-box-8>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  computed: {
    totalNumber() {
      return this.$store.state.sairoboData.totalUser.toString().split("");
    },
    activeNumber() {
      return this.$store.state.sairoboData.onlineUser.toString().split("");
    },
  }
};
</script>
<style lang="scss" scoped>
.total-number {
  height: 1rem;
  .total-number-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    font-size: 0.3rem;
    font-weight: bold;
    padding: 0 0.3rem;
    .number-item {
      display: flex;
      align-items: center;
      span {
        display: block;
      }
      ul {
        display: flex;
        li {
          background-color: rgba(19, 25, 47, 1);
          width: 0.45rem;
          height: 0.6rem;
          margin: 0 0.1rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>