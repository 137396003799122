<template>
  <dv-border-box-11
    title="学员总数"
    backgroundColor="rgba(19, 25, 47, 0.6)"
    class="pie-chart-box"
  >
    <div id="doughnutChart" style="width: 100%; height: 100%"></div>
  </dv-border-box-11>
</template>

<script>
export default {
  data() {
    return {
      option: null,
      pie: null,
      kidCount: this.$store.state.sairoboData.kidCount,
      registerCount: this.$store.state.sairoboData.registerCount,
      offlineUser: this.$store.state.sairoboData.offlineUser,
      onlineUser: this.$store.state.sairoboData.onlineUser
    };
  },

  mounted() {
    var chartDom = document.getElementById("doughnutChart");
    this.doughnutChart = this.$echarts.init(chartDom);
    this.initdoughnutChart();
    setInterval(() => {
      this.initdoughnutChart();
    }, 5000)
  },

  methods: {
    initdoughnutChart() {
      this.option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        color:['rgba(43,189,189,1)', 'rgba(253,170,110,1)', 'rgba(123,133,164,1)','rgba(222,199,15,1)'],
        legend: {
          top: "10%",
          left: "center",
          data: [
            "儿童用户",
            "成人用户",
          ],
          textStyle: {
            color: '#fff',
            fontSize: 12
          },
        },
        label: {
          formatter: '{c} 人',
        },
        toolbox: {
          show: true,
          // feature: {
          //   mark: { show: true },
          //   dataView: { show: true, readOnly: false },
          //   restore: { show: true },
          //   saveAsImage: { show: true },
          // },
        },
        series: [
          {
            name: "面积模式",
            type: "pie",
            radius: [30, 90],
            center: ["50%", "55%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 5,
            },
            data: [
              { value: this.kidCount, name: "儿童用户" },
              { value: this.registerCount, name: "成人用户" },
            ],
          },
        ],
      };
      this.option && this.doughnutChart.setOption(this.option);
    },
  },
};
</script>

<style lang="scss" scoped>
.pie-chart-box {
  width: 100%;
  // height: 3.7rem;
  flex: 1;
  padding: 0.7rem 0.2rem 0;
}
</style>