<template>
  <dv-border-box-11
    title="学员年龄分布"
    backgroundColor="rgba(19, 25, 47, 0.6)"
    class="pie-chart-box"
  >
    <div id="pieChart" style="width: 100%; height: 100%"></div>
  </dv-border-box-11>
</template>

<script>
export default {
  data() {
    return {
      option: null,
      pie: null,
      dataList: this.$store.state.sairoboData.stuAgeDistMap,
    };
  },

  computed: {
    valueList() {
      let valueList = [];
      for (var property in this.dataList)
        valueList.push({
          value: this.dataList[property].stuNum,
          name: this.dataList[property].ageRange,
        });
      return valueList;
    },

    // nameList () {
    //   let nameList = []
    //   this.dataList.forEach(item => {
    //     nameList.push(item.ageRange)
    //   })
    //   return nameList
    // },
  },

  mounted() {
    var chartDom = document.getElementById("pieChart");
    this.pieChart = this.$echarts.init(chartDom);
    this.initpieChart();
    setInterval(() => {
      this.initpieChart();
    }, 5000)
  },

  methods: {
    initpieChart() {
      this.option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        color: [
          "rgba(43,189,189,1)",
          "rgba(253,170,110,1)",
          "rgba(123,133,164,1)",
          "rgba(222,199,15,1)",
          "rgba(144,144,56,1)",
        ],
        legend: {
          top: "10%",
          left: "center",
          data: Object.keys(this.dataList),
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
        },
        label: {
          formatter: "{d}%",
        },
        toolbox: {
          show: true,
          // feature: {
          //   mark: { show: true },
          //   dataView: { show: true, readOnly: false },
          //   restore: { show: true },
          //   saveAsImage: { show: true },
          // },
        },
        series: [
          {
            name: "面积模式",
            type: "pie",
            radius: [30, 90],
            center: ["50%", "55%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 5,
            },
            data: this.valueList,
          },
        ],
      };
      this.option && this.pieChart.setOption(this.option);
    },
  },
};
</script>

<style lang="scss" scoped>
.pie-chart-box {
  width: 100%;
  // height: 3.7rem;
  flex: 1;
  padding: 0.7rem 0.2rem 0;
}
</style>