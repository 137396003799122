import * as request from '../utils/request'

let api = '';

// 获取全国JSON
export const getChinaJson = (params) => request.get(api+`/map/china.json`, params);

// 获取省JSON
export const getProvinceJSON = (path,params) => request.get(api+`/map/province/${path}.json`, params);

// 获取市JSON
export const getCityJSON = (path,params) => request.get(api+`/map/city/${path}.json`, params);

// 获取天气
export const getWeather = (params) => request.get(`https://api.asilu.com/weather_v2/`, params);

// 获取页面数据（初始化）
export const getBigScreen = (params) => request.get(`https://hexiang-ss-api.sairobo.cn/content-cloud-admin-api/bi/big_screen/info`, params);

// 获取页面数据（更新数据）
export const uodateBigScreen = (params) => request.get(`https://hexiang-ss-api.sairobo.cn/content-cloud-admin-api/bi/big_screen/user_count`, params);


