<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <top-header />
        <div class="body-box">
          <div class="body-left">
            <doughnut-chart />
            <capsule-chart />
            <range-age />
          </div>
          <div class="body-center">
            <total-number />
            <china @map-change="mapClick" @back-change="backClick" />
          </div>
          <div class="body-right">
            <stacked-area-chart />
            <pie-chart />
            <bar-chart />
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import { getBigScreen } from "../api/get-json";
import topHeader from "./topHeader";
import totalNumber from "./totalNumber";
import china from "./china";
import doughnutChart from "./doughnutChart";
import capsuleChart from "./capsuleChart";
import rangeAge from "./rangeAge";
import stackedAreaChart from "./stackedAreaChart";
import pieChart from "./pieChart";
import barChart from "./barChart";

export default {
  name: "index",
  components: {
    topHeader,
    totalNumber,
    china,
    doughnutChart,
    capsuleChart,
    rangeAge,
    stackedAreaChart,
    pieChart,
    barChart,
  },
  data() {
    return {
      loading: true,
      areaData: {
        areaCode: "000000",
        areaLevel: "county",
        name: "中国",
        areaName: "中国",
      }
    };
  },
  computed: {
    chinaMapTitle() {
      let areaName = this.areaData.areaName;
      if (areaName === "china") {
        areaName = "全国";
      }
      return areaName;
    },
  },
  created() {
    setInterval(() => {
      this.getBigScreen();
    }, 5000)
    // setInterval(() => {
    //   this.uodateBigScreen()
    // }, 3000)
  },
  methods: {
    // 获取数据
    getBigScreen() {
      getBigScreen().then((res) => {
        this.$store.commit("setSairoboState", res.data);
        this.cancelLoading();
      });
    },
    // 更新数据
    // uodateBigScreen() {
    //   uodateBigScreen().then((res) => {
    //     this.$store.commit("updateOnlineUser", res.data.onlineUser);
    //     this.$store.commit("updateTotalUser", res.data.totalUser);
    //   });
    // },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    mapClick(data) {
      this.areaData = data;
    },
    backClick(data) {
      this.areaData = data;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/index.scss";
</style>
<style lang="scss" scoped>
#index {
  .dv-border-box-10,
  .dv-border-box-12,
  .dv-border-box-13 {
    height: inherit;
  }

  .body-box {
    display: flex;
    justify-content: space-between;
    height: 100%;
    .body-left {
      // width: 23%;
      flex: 1;
      height: 92%;
      display: flex;
      flex-flow: column;
    }
    .body-center {
      // width: 50%;
      flex: 2;
      height: 86%;
      margin: 0 0.3rem;
      // background-color: rgba(19, 25, 47, 0.6);
    }
    .body-right {
      // width: 23%;
      flex: 1;
      height: 92%;
      display: flex;
      flex-flow: column;
      // height: 81%;
      // background-color: rgba(19, 25, 47, 0.6);
    }
  }

  // .topNumber {
  //   height: 100%;
  //   display: flex;
  //   justify-content: space-around;
  //   align-items: center;
  // }
}
</style>
