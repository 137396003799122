<template>
  <dv-border-box-11 title="学员数量TOP10" backgroundColor="rgba(19, 25, 47, 0.6)" class="capsule-chart-box">
    <dv-scroll-ranking-board :config="config" style="height: 100%"/>
  </dv-border-box-11>
</template>
<script>
export default {
  data() {
    return {
      config: {
        showValue: true,
        data: []
      }
    }
  },

  mounted() {
    this.initData()
  },

  methods: {
    initData() {
      let dataList = this.$store.state.sairoboData.stuProvinceDistList
      dataList.forEach((item, index) => {
        if (item.areaName === '其他') {
          dataList.splice(index, 1)
        }
      });
      this.config.data = dataList.slice(0, 10).map(item => {
        return {
          name: item.areaName,
          value: item.stuNum
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.capsule-chart-box{
  padding: 1rem 0.5rem 0.3rem;
  // height: 3.7rem;
  flex: 1;
  overflow: hidden;
}
</style>